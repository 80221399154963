<template>
  <OtherBanner :type="27" />

  <section class="filter-box" id="pageTop">
    <div class="content">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :dataSource="signupStatus"
        @onChange="signupStatusChange"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="searchVal"
              :placeholder="$t('please_enter_keywords')"
              style="width: 240px"
              @search="search"
            />
            <!-- 请输入关键字 -->
          </div>
        </template>
      </ListFilter>
    </div>
  </section>

  <section class="signup">
    <div class="content">
      <a-spin :spinning="signupLoading">
        <template v-if="signupData.length">
          <div class="cards clearfix">
            <div
              class="item fl"
              v-for="item in signupData"
              :key="item.signupId"
            >
              <SignupCard :dataSource="item" />
            </div>
          </div>
          <div class="page-Wrap">
            <a-pagination
              show-quick-jumper
              :defaultPageSize="20"
              v-model:current="currentPage"
              :show-total="(total) => $t('XB_InTotal', [pageTotal])"
              :total="pageTotal"
              @change="pageChange"
            >
              <template #itemRender="{ type, originalElement }">
                <a-button v-if="type === 'prev'">{{ $t("cm_pre") }}</a-button>
                <a-button v-else-if="type === 'next'">{{
                  $t("cm_next")
                }}</a-button>
                <renderVNode v-else :vnode="originalElement"></renderVNode>
              </template>
            </a-pagination>
          </div>
        </template>
        <a-empty v-else style="padding: 50px 0" />
      </a-spin>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import TreeFilter from "@/components/filter/TreeFilter.vue";
import ListFilter from "@/components/filter/ListFilter.vue";
import OtherBanner from "@/components/banner/OtherBanner.vue";
import SignupCard from "@/components/signup/SignupCard.vue";
import { signupList } from "@/api/project";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    TreeFilter,
    ListFilter,
    SignupCard,
    renderVNode,
    OtherBanner,
  },
  setup() {
    const { t: $t } = useI18n();
    // 状态
    const signupStatus = [
      {
        name: $t("Pub_AlreadyRegistered"),
        // 已报名
        id: 1,
      },
      {
        name: $t("Pub_NotRegistered"),
        // 未报名
        id: 2,
      },
      {
        name: $t("Pub_Lab_PendingAudit"),
        // 待审核
        id: 6,
      },
    ];
    let signupStatusActive = 0;
    const signupStatusChange = (arr) => {
      signupStatusActive = arr[0];
      getSignupList();
    };

    // 面包屑
    const crumbsClick = (data, index) => {
      let dom = document.getElementById(`tree_${index}_${data.id}`);
      dom.click();
    };

    // 搜索
    const searchVal = ref(""),
      search = () => {
        currentPage.value = 1;
        getSignupList();
      };

    // 课程
    const signupData = ref([]),
      signupLoading = ref(true),
      currentPage = ref(1),
      pageTotal = ref(1),
      getSignupList = (scroll = false) => {
        signupLoading.value = true;
        signupList({
          page: currentPage.value,
          pageSize: 20,
          name: searchVal.value,
          signupStatus: signupStatusActive,
        }).then((res) => {
          signupLoading.value = false;
          if (res.ret === 0) {
            pageTotal.value = res.data.totals;
            signupData.value = res.data.list || [];
            if (scroll) {
              setTimeout(() => {
                document.getElementById("pageTop").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                });
              }, 0);
            }
          }
        });
      },
      pageChange = (n) => {
        currentPage.value = n;
        getSignupList(true);
      };
    getSignupList();

    return {
      crumbsClick,
      signupStatus,
      signupStatusChange,
      searchVal,
      search,
      getSignupList,
      signupData,
      signupLoading,
      currentPage,
      pageTotal,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.filter-box {
  background-color: @color-page-gray;
  padding-bottom: 14px;
  .content {
    background-color: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
    .mixinWrap();
    .search {
      .mixinFlex(flex-end);
    }
  }
}

.signup {
  background-color: @color-page-gray;
  .content {
    .mixinWrap();
    padding: 22px 0 42px;
    .cards {
      .item {
        margin-right: 24px;
        margin-bottom: 24px;
        &:nth-child(4n) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
