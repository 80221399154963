<template>
  <div class="card">
    <div class="cover">
      <img :src="dataSource.coverImg" alt="cover" />
    </div>
    <div class="content">
      <h5>{{ dataSource.taskName }}</h5>
      <div class="info">
        <div class="item">
          <ClockCircleOutlined style="color: #999; font-size: 12px" />
          <span
            >{{ dateFormat(dataSource.signupStart) }} ～
            {{ dateFormat(dataSource.signupEnd) }}</span
          >
        </div>
        <div class="item">
          <TeamOutlined style="color: #999; font-size: 12px" />
          <span>{{
            dataSource.signupUsers <= 0
              ? $t("signup.unlimited")
              : $t("signup.people", [dataSource.signupUsers])
          }}</span>
          <!-- 无限制 : 人 -->
        </div>
      </div>
      <router-link
        :to="{ path: '/signup/detail', query: { id: dataSource.taskId } }"
        class="link"
      >
        <div
          class="status"
          v-if="
            dataSource.signupStatus == 2 ||
            dataSource.signupStatus == 7 ||
            dataSource.signupStatus == 0 ||
            dataSource.signupStatus == 9
          "
        >
          {{ $t("Pub_Tab_GoSign") }}
          <!-- 去报名 -->
        </div>
        <div class="status gray" v-else-if="dataSource.signupStatus == 1">
          {{ $t("Pub_AlreadyRegistered") }}
          <!-- 已报名 -->
        </div>
        <div class="status red" v-else-if="dataSource.signupStatus == 6">
          {{ $t("Pub_Lab_PendingAudit") }}
          <!-- 待审核 -->
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { dateFormat } from "@/utils/tools";
export default {
  name: "SignupCard",
  props: {
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup() {
    return {
      dateFormat,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  display: block;
  width: 282px;
  height: 357px;
  border-radius: 8px;
  box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.15);
  background-color: #fff;
  transition: all 0.3s ease;
  position: relative;
  .cover {
    .mixinImgWrap(282px; 184px);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      background-color: rgba(255, 255, 255, 0);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transition: all 0.3s ease;
    }
  }
  .content {
    padding: 20px 10px;
    border-radius: 0 0 8px 8px;
    h5 {
      font-size: 16px;
      .mixinEllipsis(23px);
      margin: 0;
    }
    .info {
      .item {
        .mixinFlex(flex-start; center);
        color: #999;
        font-size: 12px;
        margin-top: 6px;
        span:last-child {
          margin-left: 4px;
        }
      }
    }
    .link {
      display: block;
      width: 138px;
      height: 40px;
      margin: 20px auto 0;
      .status {
        width: 100%;
        height: 100%;
        color: @color-theme;
        font-size: 16px;
        line-height: 38px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid @color-theme;
        &.gray {
          color: #666;
          border-color: #666;
        }
        &.red {
          color: #d32f2f;
          border-color: #d32f2f;
        }
      }
    }
  }
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 0 15px 0 rgba(149, 149, 149, 0.25);
    .cover {
      &::after {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
